@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

@layer base {
  html {
    @apply font-poppins;
  }
}

@font-face {
  font-family: 'OstrichSansHeavy';
  src: url("../public/fonts/OstrichSansHeavy.otf");
}